:root {
  --black: #3e3c3b;
  --blue: #4297ff;
  --grey: #bbb;
  --light-grey: #ebebeb;
  --alinta-orange: #f26722;
  --pvebanner-blue: #4472C4;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

body {
  margin: 0;
  color: var(--black);
  font-family: 'Nunito', sans-serif;
}

a {
  color: var(--black);
  font-weight: bold;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

button {
  color: inherit;
}
